import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import AllIncluded from '../components/AllIncluded';
import Header from '../components/Header';
import Hero from '../components/Hero';
import Partners from '../components/Partners';
import Premium from '../components/Premium';
import ReadyToStart from '../components/ReadyToStart';
import Footer from '../components/Footer';
import EventTypeInfo from '../components/EventTypeInfo';
import Events from '../components/Events';
import FAQ from '../components/FAQ';
import SEO from '../components/SEO';

import metaImage from '../images/meta/on-site-event-streaming.jpg';

const OnSiteEvents = () => {
  return (
    <div>
      <SEO
        title="On-site event streaming"
        description="Extend your reach beyond your physical event capacity. We take care of all technical aspects of streaming your on-site event live."
        image={metaImage}
      />

      <Header />
      <Hero
        title="On-site event webcasting services you can rely on"
        subtitle="Our team will guide you every step of the way and deliver the best online experience for your audience"
      />
      <Partners />
      <EventTypeInfo
        image={
          <StaticImage
            src="../images/on-site-events/on-site-event-streaming.png"
            alt="Collage of on-site event being streamed"
          />
        }
        title="Extend your reach beyond your physical event capacity"
        text="Engage your community around the world, wherever they watch. Stream to any device across social media platforms or your own website. Throw stellar events and make your organization thrive, with webcasting."
        checks={[
          'Political seminars',
          'Public hearings',
          'Scientific meetings',
          'Corporate events',
        ]}
      />
      <AllIncluded />
      <Premium />
      <Events
        events={[
          {
            image: 'european-commission-stream.jpg',
            title: 'Single session, multi camera setup',
            client: 'European Commission',
            link: 'https://app.livecasts.eu/information-day-on-civil-protection-programme-2020',
            name: 'Information Day',
            description:
              'The event was live streamed with a multi camera setup. Submitted questions were answered during the Q&A sessions.',
          },
          {
            image: 'my-micro-invest-stream.jpg',
            title: 'Live crowdfunding event promoting startups',
            client: 'MyMicroInvest',
            link: 'https://app.livecasts.eu/magic-5',
            name: 'Magic 5 Live Crowdfunding',
            description:
              'The event was live streamed with a multi camera setup, presenting startups such as Zen Car.',
          },
        ]}
      />
      <ReadyToStart />
      <FAQ type="on-site" />
      <Footer />
    </div>
  );
};

export default OnSiteEvents;
